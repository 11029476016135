//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { inviteService } from "@/service/inviteService";

export default {
  data() {
    return {
      peopleForm: {
        HospitalName: "",
        Department: "",
        UserNames: [{ name: "" }],
        AttendNum: 1
      }
    };
  },
  created() {},
  methods: {
    peopleAdd() {
      this.peopleForm.UserNames.push({ name: "" });
      this.peopleForm.AttendNum = this.peopleForm.UserNames.length;
    },
    peopleClose(index) {
      this.peopleForm.UserNames.splice(index, 1);
      this.peopleForm.AttendNum = this.peopleForm.UserNames.length;
    },
    goBack() {
      this.$router.go(-1);
    },
    joinMeeting(e) {
      e.preventDefault();
      let params = {
        HospitalName: this.peopleForm.HospitalName,
        Department: this.peopleForm.Department,
        UserNames: this.peopleForm.UserNames.map(item => {
          return item.name;
        }),
        AttendNum: this.peopleForm.AttendNum,
        // MettingId: this.$store.state.meetingInvite.confId
        // MettingId: sessionStorage.getItem("confId")
        MettingId: this.$route.query.confId
      };
      // console.log(params);
      inviteService.JoinMeeting(params).then(res => {
          if(res.success) {
            let extra = this.peopleForm.UserNames.length == 1 ? "" : `等${this.peopleForm.UserNames.length}人`
            let uName = `Ⓐ${this.peopleForm.UserNames[0].name}${extra}（${this.peopleForm.HospitalName}-${this.peopleForm.Department}）`;
            // window.open(`${res.content.joinUrl}?uname=${this.peopleForm.UserNames[0].name}${extra}`);
            window.location.href = `${res.content.joinUrl}?uname=${encodeURI(uName)}`;
          }
      });
    }
  }
};
